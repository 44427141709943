@import "styles/variables";

.container {
  max-width: 1400px;
  margin: 0 auto;
}

.row {
  position: relative;
  clear: both;
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.col {
  display: block;
  width: 100%;
  padding: 0 $general-gutter;
  &.no-padding {
    padding: 0;
  }
  @media (min-width: $sm) {
    &.col-6 {
      width: 50%;
      flex-basis: 50%;
    }

    &.col-3 {
      width: 25%;
      flex-basis: 25%;
    }

    &.col-2 {
      width: 16.66%;
      flex-basis: 16.66%;
    }

    &.col-9 {
      width: 75%;
      flex-basis: 75%;
    }

    &.col-4 {
      width: 33.33%;
      flex-basis: 33.33%;
    }

    &.col-8 {
      width: 66.66%;
      flex-basis: 66.66%;
    }
  }
}

#footer {
  background-color: $primary-color;
  color: white;
  padding: $general-gutter * 4 0;
  padding-bottom: 0;
  a {
    color: white;
    &.map-anchor {
      color: $secondary-color;
    }
    &:hover {
      color: $secondary-color;
    }
  }
  .socials {
    text-align: center;
    font-size: 4em;
    a {
      margin-right: $general-gutter * 2;
    }
  }
  h3 {
    color: white;
    margin-bottom: $general-gutter;
    font-weight: bold;
    font-size: 1.5em;
  }
  address {
    font-style: normal;
    font-size: 0.9em;
    text-align: center;
    margin-top: $general-gutter * 3;
    a {
      text-decoration: underline;
    }
    img {
      display: inline-block;
      margin-bottom: $general-gutter;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      a {
        display: block;
        padding: $general-gutter / 2 $general-gutter / 3;
        font-size: 0.9em;
        &:hover {
          color: white;
          background-color: $primary-color-hover;
        }
      }
    }
  }
  .copyright {
    text-align: center;
    margin-top: $general-gutter * 3;
    padding: $general-gutter;
    font-size: 0.9em;
    a {
      text-decoration: underline;
    }
  }

  @media (min-width: $sm) {
    //todo
  }
}

.newsletter {
  background: $secondary-color;
  padding: $general-gutter * 4 0;
  color: white;
}
