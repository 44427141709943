@import "styles/variables";

$search-input-button: 60px;

.search-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  form {
    width: 100%;
  }
  input,
  button {
    height: 35px;
    padding: 0;
    margin: 0;
    display: inline-block;
    border: none;
    box-shadow: none;
    background-color: rgb(242, 242, 242);
  }
  input {
    width: 100%;
    padding: 0 60px 0 10px !important;
    font-size: 1em;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    border-radius: 17px;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
    -moz-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
    box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: $search-input-button;
    padding: 5px 15px 0;
    text-align: center;
    -webkit-border-top-right-radius: 17px;
    -webkit-border-bottom-right-radius: 17px;
    -moz-border-radius-topright: 17px;
    -moz-border-radius-bottomright: 17px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      background-color: #e6e6e6;
    }
    svg {
      fill: $primary-color;
    }
  }
  @media (min-width: $md) {
    width: 400px;
    input,
    button {
      background-color: white;
    }
    input {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .results {
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    z-index: 1000;
    border-top: 5px solid $secondary-color;
    box-shadow: 0px 10px 24px 2px rgba(53, 48, 48, 0.3);

    @media (min-width: $md) {
      left: -50%;
    }

    .resultsWrapper {
      position: relative;
      display: flex;
      width: 100%;
      background: white;
      flex-direction: column; 

      @media (min-width: $md) {
        width: 960px;
        height: 500px;
        flex-direction: row;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        color: $grey-color;
        display: block;
        font-size: 20px;
        cursor: pointer;
      }

      h2 {
        height: 30px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .resultsLeft {
        width: 100%;
        background: rgba($grey-color, 0.3);
        padding: 10px 20px;

        @media (min-width: $md) {
          width: 30%;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0 0 20px 0;

          &.inline {
            width: 100%;

            li {
              display: inline-flex;
              margin: 5px 5px 0 0;
            }
          }

          li {
            list-style: none;

            a {
              padding: 5px 0;
              color: $primary-color;

              &:hover {
                text-decoration: underline;
              }

              &.tag {
                border-radius: 15px;
                background: white;
                padding: 5px 10px;
                font-size: 13px;

                &:hover {
                  background: $primary-color;
                  color: white;
                }
              }
            }
          }
        }
      }

      .topProduct {
        width: 100%;
        background: white;
        border-right: 1px solid rgba($grey-color, 0.3);
        padding: 10px 20px;

        @media (min-width: $md) {
          width: 30%;
        }
      }

      .resultsRight {
        width: 100%;
        background: white;
        padding: 10px 20px;
        overflow-y: scroll;

        @media (min-width: $md) {
          width: 40%;
        }
      }
    }
  }
}
