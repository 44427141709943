@import "styles/variables";

.newsletter {
  padding: 0 $general-gutter;
  :global(.ant-row) {
    margin-bottom: 0px;
  }
  .agreementCheckboxWrapper {
    label {
      color: #ffffff;
    }
  }
  h3 {
    color: white;
    font-size: 2em;
  }
}
